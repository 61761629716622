<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Prelab Quiz - Exploring the Properties of Gasses</h2>

      <p class="mb-n3">a) How many gas laws are being explored in this experiment?</p>

      <v-radio-group v-model="inputs.input1" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">b) How many data points should be collected per gas law?</p>

      <v-radio-group v-model="inputs.input2" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options2"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">c) What will be measured in Boyle's Law besides pressure?</p>

      <v-radio-group v-model="inputs.input3" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options3"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">d) What will we be measuring in Charles' Law besides volume?</p>

      <v-radio-group v-model="inputs.input4" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options4"
          :key="'pt-4-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">e) Gay-Lussac's Law compares ___________ and ___________.</p>

      <v-radio-group v-model="inputs.input5" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options5"
          :key="'pt-5-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        f) What information from the graphs will need to be discussed in the error analysis section
        of your report? Select all that apply.
      </p>

      <p v-for="option in options6" :key="'pt-6-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input6" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'HawkeyePrelabQuiz7',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: [],
      },
      options1: [
        {text: '$2$', value: '2'},
        {text: '$3$', value: '3'},
        {text: '$4$', value: '4'},
        {text: '$5$', value: '5'},
        {text: '$6$', value: '6'},
      ],
      options2: [
        {text: '$2$', value: '2'},
        {text: '$3$', value: '3'},
        {text: '$4$', value: '4'},
        {text: '$5$', value: '5'},
        {text: '$6$', value: '6'},
      ],
      options3: [
        {text: '$\\text{Temperature}$', value: 'a'},
        {text: '$\\text{Moles}$', value: 'b'},
        {text: '$\\text{Mass}$', value: 'c'},
        {text: '$\\text{Volume}$', value: 'd'},
        {text: '$\\text{None of the above}$', value: 'e'},
      ],
      options4: [
        {text: '$\\text{Temperature in Fahrenheit}$', value: 'a'},
        {text: '$\\text{Temperature in Celsius}$', value: 'b'},
        {text: '$\\text{Pressure}$', value: 'c'},
        {text: '$\\text{Temperature in Kelvin}$', value: 'd'},
        {text: '$\\text{Temperature in Rankine}$', value: 'e'},
      ],
      options5: [
        {text: '$\\text{Pressure and absolute temperature}$', value: 'a'},
        {text: '$\\text{Pressure and moles}$', value: 'b'},
        {text: '$\\text{Volume and absolute temperature}$', value: 'c'},
        {text: '$\\text{Pressure and volume}$', value: 'd'},
        {text: '$\\text{None of the above}$', value: 'e'},
      ],
      options6: [
        {text: '$\\text{Percent RSD values}$', value: 'a'},
        {text: '$\\text{Percent errors}$', value: 'b'},
        {text: '$\\text{R}^2\\text{ values from each graph}$', value: 'c'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
